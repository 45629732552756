/* eslint-disable react/no-unknown-property */
import * as React from "react"
import Container from "components/container"
import styled from "@emotion/styled"
import { getColorScheme, getWhiteText, theme as themeCommon } from "common/colorScheme"
import { css } from "@emotion/react"
import BackgroundAnimation from "components/BackgroundAnimation"
import { graphql } from "gatsby"
import BrandImagesNew from "components/BrandImagesNew"
import BrandImagesWithReviews from "components/BrandImagesWithReviews"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { H2, P } from "../headings"
import Button from "../button"
import Media from "../Media"
import { SectionMediaAlign, ISectionProps, MediaSize, SectionLayoutAlign } from "./section.model"
import { elementData, formatMultilineText } from "./utils"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import Card from "./Card/Card"
import { useSectionTheme } from "./SectionThemeContext"
import {AdditionalText} from "./ImageRight"

interface IImageRightNewProps extends ISectionProps {}

const backgroundGradientWrapper = css`
	height: 100%;
	width: 100%;
	position: relative;
`

const hideOverflow = css`
	top: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
	pointer-events: none;
`

export const hideOnMobile = css`
	display: none;

	${mediaBreakpoint(BreakPoints.LG)} {
		display: block;
	}
`

const containerStyles = css`
	display: block;
	padding-top: ${themeCommon.spacing(6)};
	padding-bottom: ${themeCommon.spacing(6)};
	${mediaBreakpoint(BreakPoints.MD)} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
	}
`

const SubTitle = styled.h3`
	font-size: 2rem;
	line-height: 2.8rem;
	font-weight: 400;
`
const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	flex-direction: column;

	div:not(:nth-of-type(1)) {
		margin-top: ${themeCommon.spacing(2)};
	}

	&.hideOnMobile {
		display: none;

		${mediaBreakpoint(BreakPoints.SM)} {
			display: flex;
		}
	}

	&.hideOnDesktop {
		display: flex;
		margin-top: ${themeCommon.spacing(6)};

		${mediaBreakpoint(BreakPoints.SM)} {
			display: none;
		}
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		width: fit-content;
		flex-direction: row;

		div:not(:nth-of-type(1)) {
			margin-top: 0px;
		}
	}
`

const buttonStyles = css`
	width: 100%;

	&:not(:first-of-type) {
		margin-top: ${themeCommon.spacing(1.5)};

		${mediaBreakpoint(BreakPoints.SM)} {
			margin-left: ${themeCommon.spacing(3)};
			margin-top: 0;
		}
	}
`

const Cover = styled.div`
	display: flex;
	content-visibility: initial;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: ${themeCommon.spacing(7.5, 0)};
		align-items: center;
	}
`

const TextContainer = styled.div`
	flex-basis: 42%;
	${H2} {
		${mediaBreakpoint(BreakPoints.LG)} {
			white-space: pre;
		}
	}
`

const MediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-basis: 100%;
	max-width: 100%;
	${mediaBreakpoint(BreakPoints.MD)} {
		align-items: unset;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		flex-basis: 54%;
		max-width: 54%;
		margin: 0;
	}
`

const alignContentFirsttStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(6.5)};

		${mediaBreakpoint(BreakPoints.LG)} {
			margin-top: ${themeCommon.spacing(4)};
		}
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${TextContainer} {
			order: 1;
		}
		${MediaContainer} {
			order: 2;
			margin-top: 0;
		}
	}
`

const alignMediaFirstStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(4)};
		margin-bottom: ${themeCommon.spacing(2)};
		order: 1;
	}

	${TextContainer} {
		order: 2;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${MediaContainer} {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
`

const cardsContainerStyles = css`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 0rem;
	&:empty {
		display: none;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		padding-top: ${themeCommon.spacing(5)};
		margin: ${themeCommon.spacing(0, 0, -4)};
		> div {
			flex-basis: calc(33.333% - ${themeCommon.spacing(7.5)});
		}
	}
`

const MediaWrapper = styled.div`
	max-width: 100%;
	width: auto;
`

const MediaWrapperDesktop = styled.div`
	max-width: 100%;
	width: auto;
	display:none;
	${mediaBreakpoint(BreakPoints.SM)} {
		display:none;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		align-items: unset;
		display:block;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		display:block;
	}
`

const MediaWrapperMobile = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-basis: 100%;
	max-width: 100%;
	display:block;
	${mediaBreakpoint(BreakPoints.SM)} {
		display:block;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		align-items: unset;
		display:none;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		flex-basis: 54%;
		max-width: 54%;
		margin: 0;
		display:none;
	}
`
const layoutAlignStyles = {
	[SectionLayoutAlign.ContentFirst]: alignContentFirsttStyles,
	[SectionLayoutAlign.MediaFirst]: alignMediaFirstStyles,
}

const getMediaSize = (size: MediaSize) => {
	switch (size) {
	case MediaSize.VerySmall:
		return css`
				width: 300px;
			`
	case MediaSize.Small:
		return css`
				width: 350px;
			`
	case MediaSize.MediumSmall:
		return css`
				width: 400px;
			`
	case MediaSize.Medium:
		return css`
				width: 500px;
			`
	case MediaSize.Full:
		return css`
				${mediaBreakpoint(BreakPoints.LG)} {
					margin: ${themeCommon.spacing(-7.5, 0)};
				}
			`
	default:
		return css``
	}
}

const getMediaAlign = (alignment: SectionMediaAlign) => {
	switch (alignment) {
	case SectionMediaAlign.Left:
		return css`
				align-items: flex-start !important;
			`
	case SectionMediaAlign.Center:
		return css`
				align-items: center !important;
			`
	case SectionMediaAlign.Right:
	default:
		return css`
				align-items: flex-end !important;
			`
	}
}

const BulletPointContainer = styled.div`
	[data-element="card-container"] {
		flex-direction: row;
		margin-left: 0;
	}
	[data-element="media-container"] {
		align-self: center;
		width: 4.2rem;
		margin: 0 1rem 1rem 0
	}
	span {
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.3rem;
	}
	p {
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.3rem;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		p {
			text-align: left;
			margin-left:0;
		}
	}

`

const BulletPointsWrapper = styled.div`
`

const ImageFitToSection = styled.div`
	position: absolute;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
	display: none;
		img {	
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		${mediaBreakpoint(BreakPoints.SM)} {
			display: block;
		}
`
const ImageRightNew: React.FC<IImageRightNewProps> = ({
	colorScheme,
	title,
	titleLevel,
	description,
	buttons,
	media,
	mobileMedia,
	mediaAlign,
	mediaSize,
	layoutAlignment,
	customStyle,
	cards,
	backgroundGradientsBottomLeft,
	backgroundGradientsBottomRight,
	backgroundGradientsTopLeft,
	backgroundGradientsTopRight,
	backgroundGradientsWidth,
	brandImages,
	subTitle,
	additionalText,
	bulletpoints,
	mediaToFitSection,
	brandImagesScrolling
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()
	const whiteText = theme === "white-text"
	const gradientsWidth = backgroundGradientsWidth ?? 50
	const backgroundGradients =
		backgroundGradientsBottomLeft ||
		backgroundGradientsBottomRight ||
		backgroundGradientsTopLeft ||
		backgroundGradientsTopRight

	return (
		<section css={[customStyle, getColorScheme(colorScheme)]}>
			{mediaToFitSection ? <ImageFitToSection data-element="img-to-fit"><Media {...mediaToFitSection} /></ImageFitToSection>: null }
			<div css={backgroundGradientWrapper} data-element={elementData.gradientWrapper}>
				<div data-element="hidden" css={[hideOverflow, hideOnMobile]}>
					<Container
						customStyles={css`
							position: relative;
							height: calc(100% - 202px);
							margin-top: 202px;
							transform: translateX(${100 - gradientsWidth}%);
						`}
					>
						{backgroundGradients && (
							<BackgroundAnimation
								width={gradientsWidth}
								backgroundGradientsBottomLeft={backgroundGradientsBottomLeft}
								backgroundGradientsBottomRight={backgroundGradientsBottomRight}
								backgroundGradientsTopLeft={backgroundGradientsTopLeft}
								backgroundGradientsTopRight={backgroundGradientsTopRight}
							/>
						)}
					</Container>
				</div>
				<Cover data-element={elementData.cover}>	
					<Container css={[containerStyles, layoutAlignStyles[layoutAlignment]]} data-element={elementData.container}>
						<TextContainer data-element={elementData.textContainer} css={getWhiteText(whiteText)}>
							{subTitle && <SubTitle data-element="subtitle">{subTitle}</SubTitle>}
							<H2 css={getSizeStyles(titleSize)} as={titleLevel || "h2"} data-element="title-text">
								{title}
							</H2>
							{description && <P>{formatMultilineText(description)}</P>}
							{bulletpoints ?
								<BulletPointsWrapper  data-element="bulletpoints-wrapper">
									{(bulletpoints || []).map(card => {
										const { id } = card
										return (
											<BulletPointContainer key={id} >
												<Card {...card} />
											</BulletPointContainer>
										)
									})}
								</BulletPointsWrapper>	
								: null
							}
							<ButtonsContainer className="hideOnMobile">
								{(buttons || []).map(button => {
									const { id } = button

									return (
										<div key={id} css={buttonStyles}>
											<Button {...button} />
										</div>
									)
								})}
							</ButtonsContainer>
						</TextContainer>
						{media && mobileMedia || mediaToFitSection ? 
							(<MediaContainer css={getMediaAlign(mediaAlign)} data-element={elementData.bannerMediaContainer}>
								{media ? (
									<MediaWrapperDesktop css={getMediaSize(mediaSize)} data-element={elementData.bannerMedia}>
										<Media
											imgStyle={{
												objectFit: "contain",
											}}
											{...media}
										/>
									</MediaWrapperDesktop>
								) : null}
								{mobileMedia ? (
									<MediaWrapperMobile css={getMediaSize(mediaSize)} data-element={elementData.bannerMedia}>
										<Media
											imgStyle={{
												objectFit: "contain",
											}}
											{...mobileMedia}
										/>
									</MediaWrapperMobile>
								) : null}
							</MediaContainer>): 
							<MediaContainer css={getMediaAlign(mediaAlign)} data-element={elementData.bannerMediaContainer}>
								{media ? (
									<MediaWrapper css={getMediaSize(mediaSize)} data-element={elementData.bannerMedia}>
										<Media
											imgStyle={{
												objectFit: "contain",
											}}
											{...media}
										/>
									</MediaWrapper>
								) : null}
							</MediaContainer> }
						{cards && cards.length ? (
							<Container css={cardsContainerStyles}>
								{(cards || []).map(card => {
									const { id } = card
									return <Card key={id} {...card} />
								})}
							</Container>
						) : null}
						{ buttons ?
							<ButtonsContainer className="hideOnDesktop">
								{(buttons || []).map(button => {
									const { id } = button

									return (
										<div key={id} css={buttonStyles}>
											<Button {...button} />
										</div>
									)
								})} 
							</ButtonsContainer>: null }
					</Container>
					{additionalText && (
						<Container>
							<AdditionalText
								sectionTheme={theme}
								dangerouslySetInnerHTML={{ __html: additionalText?.childMarkdownRemark.html }}
							/>
						</Container>
					)}
					{brandImages && !brandImagesScrolling ? <BrandImagesNew brandImages={brandImages} />: null}
					{BrandImagesNew && brandImagesScrolling ? (
						<BrandImagesWithReviews compact transparent images={brandImages.logos} />
					) : null}
				</Cover>
			</div>
		</section>
	)
}

export const query = graphql`
	fragment BrandImagesNew on ContentfulBrandImagesNew {
		__typename
		heading {
			heading
		}
		logos {
			...Media160
		}
	}
`

export default React.memo(ImageRightNew)
